.field-variants {
  &__header {
    margin-bottom: 15px !important;
  }

  &__item {
    margin-bottom: 7px;
    display: flex;

    button {
      margin-left: 7px;
    }
  }
}
