.page-empty {
  padding: 4px;
  background-color: #fff;
  height: 100vh;

  &__button-go-home {
    position: fixed;
    top: 12px;
    right: 12px;
  }
}
