.field-month-year {
  width: 100%;
  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__month-input {
    margin-right: 7px;
    flex-grow: 1;
  }

  &__year-input {
    width: 30%;
  }
}
