.button-confirm {
  display: inline-block;

  &--fullwidth {
    width: 100%;
  }

  &__content {
    min-width: 320px;
  }
}
