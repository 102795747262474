.form-field {
  margin-bottom: 20px;

  &__input {
    display: flex;
    align-items: flex-start;
  }

  &__extra {
    display: flex;
    & > * {
      margin-right: 3px;
    }
  }

  &__children {
    width: 100%;
    position: relative;
  }

  &__prompt {
    position: absolute;
    right: -30px;
    top: calc(50% - 13px);
    cursor: pointer;
  }

  &--not-error {
    margin-bottom: 0;
  }

  &__error {
    position: absolute;
    color: red;
    font-size: 0.7rem;
    left: 0.3rem;
  }

  &__icon-help {
    opacity: .5;
  }
}