.button-search {
  &__badge {
    position: absolute;
    top: 9px;
    right: 5px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    line-height: 1;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffa726;
    border-radius: 10px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-sizing: border-box;
  }
}