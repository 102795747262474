.progress-circular-fullpage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &--dark {
    background-color: #121212;
  }
}
