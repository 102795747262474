.widgets-list {
  margin-bottom: 30px;

  &__header {
    margin-bottom: 7px !important;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 12px; 
  }

  &__item {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  @media only screen and (max-width: 1400px) {
    &__items {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  @media only screen and (max-width: 1200px) {
    &__items {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  @media only screen and (max-width: 1000px) {
    &__items {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (max-width: 768px) {
    &__items {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 576px) {
    &__items {
      grid-template-columns: 1fr 1fr;
    }
  }
}