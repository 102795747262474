.field-select {
  position: relative;

  &__input {
    position: relative;
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  &__dropdown-icon {
    position: absolute;
    right: 7px;
    top: 0px;
    bottom: 0;
    opacity: .65;
    display: flex;
    align-items: center;
  }

  &__dropdown {
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    z-index: 1000000 !important;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 0 0 4px 4px
  }

  &__search {
    padding: 12px;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.14);
  }

  &__items {
    padding: 12px 0 0;
    overflow: auto;
  }

  &__button-load {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nothing {
    padding: 12px;
    opacity: .8;
  }

  &--opened {
    .field-select__dropdown-icon {
      rotate: 180deg;
    }
  }

  &--dark {
    .field-select__dropdown {
      background-color: #2f2f2f;
    }
  }
}
