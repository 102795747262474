.input-file {
  border-radius: 4px;
  padding: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;

  &__name {
    margin-left: 7px;
  }
}