.pagination {
  display: flex;
  align-items: center;

  button {
    margin-left: 7px;
  }

  .pagination__display {
    margin-left: 7px;
  }
}

@media only screen and (max-width: 930px) {
  .pagination__buttons {
    display: none;
  }
}

@media only screen and (min-width: 930px) {
  .pagination__display {
    display: none;
  }
}
