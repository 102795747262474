.buttons {
  &--vertical {
    button {
      margin-bottom: 7px;
    }
  }

  &--default {
    button {
      margin-right: 7px;
    }
  }
}
