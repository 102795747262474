.divider-vertical {
  position: relative;
  padding: 7px 3px 0 0;

  &::after {
    position: absolute;
    background-color: #e0e0e0;

    content: '';
    width: 1px;
    height: calc(100% - 14px);
    left: 1px;
  }

  &--dark {
    &::after {
      background-color: #424242;
    }
  }
}
