.data-card {
  overflow: visible !important;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__loader,
  &__no-data {
    padding: 15px;
  }

  &__extra {
    display: flex;
    align-items: center;
    
    button,
    div {
      margin-left: 7px;
    }
  }

  &--mb12 {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .data-card {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__extra {
      width: 100%;
      justify-content: flex-end;
    }

    &__title {
      margin-bottom: 7px;
    }
  }
}