.result-view {
  table {
    border-collapse: collapse;
  }

  td {
    border: 1px solid black;
    padding: .3em 1em;
  }
  
  th {
    border: 1px solid black;
    padding: .3em 1em;
    text-align: center;
  }
}
