.change-password {
  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-size: 20px;
  }

  &__buttons {
    button {
      margin-left: 7px;
    }
  }

  &__form {
    button {
      margin-right: 7px;
    }
  }
}
