.card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 20px;
  background-color: #fff;

  &__buttons {
    button {
      margin-left: 7px;
    }
  }

  &--dark {
    background-color: #212121;
  }
}
