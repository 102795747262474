.button-icon-square {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  
  background-color: #fff;
  color: #424242;

  &:hover {
    background-color: #f0f0f0;
    color: #212121;
  }

  &--active {
    background-color: #f0f0f0;
    color: #212121;
  }

  &--size-medium {
    width: 40px;
    height: 40px;
  }

  &--size-large {
    width: 56px;
    height: 56px;
  }

  &--dark {
    background-color: #4a4a4a;
    color: #fff;
    border: none;
    transition: background-color .3s ease;
    
    &:hover {
      background-color: #525252;
      color: #f0f0f0;
    }
  }
}
