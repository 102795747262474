.card-image {
  height: 128px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &__control-panel {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(85, 108, 214, 0);
    transition: background-color .3s ease;
  }

  &__loader {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: none;
  }

  &__buttons-panel {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: none;
  }

  &--loading .card-image__control-panel {
    background-color: rgba(85, 108, 214, 1);
  }

  &--loading .card-image__loader {
    display: flex;
  }

  &:hover .card-image__control-panel {
    background-color: rgba(85, 108, 214, 0.3);
  }

  &:hover .card-image__buttons-panel {
    display: inline-flex;
  }
}
