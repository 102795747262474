.widget-link {
  width: 100%;
  height: 100%;

  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.13);
  transition: box-shadow 0.3s ease;

  position: relative;
  overflow: hidden;

  &__title {
    color: #fafafa;
    font-weight: 500;
  }

  &__icon {
    color: #fafafa;
    position: absolute;
    bottom: -34px;
    right: -34px;
    opacity: 0.3;

    svg {
      width: 12rem;
      height: 12rem;
    }
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.11), 0 3px 6px rgba(0,0,0,0.18);
  }
}
