.toolbar {
  h6  {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: flex;
    align-items: center;

    & > button {
      margin-right: 3px;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 3px;
    }
  }
}
