.page-base {
  height: 100vh;

  display: flex;
  flex-direction: column;

  &__app-bar {
    margin-bottom: 24px;
  }

  &__content {
    height: 100%;

    &--fullwidth {
      padding: 0 12px;
    }
  }

  &__breadcrumbs {
    margin-bottom: 12px;
  }

  &__children {
    height: 100%;
    padding-bottom: 24px;
  }
}
