.field-multiselect {
  &__input {
    position: relative;
    padding: 12px 12px 0px;
    background-color: rgba(#000, 0.06);
    border-bottom: 1px solid #8b8b8b;
    border-radius: 4px 4px 0 0;
    color: #616161;
    cursor: pointer;
    min-height: 56px;

    display: flex;
    align-items: center;
    transition: all .1s;
  }

  &__chips {
    margin-top: 18px;
    & > * {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  &__label {
    padding-bottom: 10px;
    transition: all .07s;
  }

  &__dropdown-icon {
    position: absolute;
    right: 7px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  &__dropdown {
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1000000;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 0 0 4px 4px
  }

  &__search {
    padding: 12px;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.14);
  }

  &__items {
    padding: 12px 0 0;
    overflow: auto;
  }

  &__button-load {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nothing {
    padding: 12px;
    opacity: .8;
  }

  &--opened {
    .field-select__dropdown-icon {
      rotate: 180deg;
    }
  }

  &--not-empty {
    .field-multiselect__label {
      font-size: 12px;
      position: absolute;
      top: 6px;
    }
  }

  &--dark {
    .field-multiselect__children {
      position: relative;
      z-index: 100;
    }

    .field-multiselect__dropdown {
      background-color: #2f2f2f;
    }

    .field-multiselect__dropdown-icon svg {
      fill: #fff;
      opacity: .6;
    }

    .field-multiselect__input {
      background-color: rgba(255, 255, 255, 0.09);
      border-bottom: 1px solid #fff;
  
      &:hover {
        background-color: rgba(#fff, .13);
      }
    }

    .field-multiselect__label {
      color: rgba(#fff, 0.7);
    }
  }

  &--dark.field-multiselect--opened &__label {
    color: #556cd6;
  }
}
